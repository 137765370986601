import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/cuck_logo_400x400.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../asset/cuck_logo_400x400.png';

import Dexcreener from '../asset/socials/dex_screener.png';
import Dextools from '../asset/socials/dextool.png';
import Telegram from '../asset/socials/telegram.png';
import Twitter from '../asset/socials/twitter.png';
import Coingecko from '../asset/socials/coingecko.png';

const MainSection = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "72px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            width: (window.innerWidth*3/20)+"px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        whitepaperButtonStyle: {
            width: (window.innerWidth*3/20)+"px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack,
            backgroundColor: appContext.colors.primaryWhite,
        },

        imageAsset1: {
            width: "20%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/20)+"px",
            padding: "0px",
            marginTop: '20px',
            marginBottom: '20px'
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
        } 
    }

    const mobileStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            height: (window.innerHeight-60)+"px",
            padding: "30px",
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "48px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "140%",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        whitepaperButtonStyle: {
            width: (window.innerWidth*4/10)+"px",
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack,
            backgroundColor: appContext.colors.primaryWhite,
        },

        imageAsset1: {
            width: "30%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/10)+"px",
            padding: "0px",
            marginTop: '20px',
            marginBottom: '20px'
        },

        

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <Image style={styles.imageAsset1} src={Asset1} />
                <h1 style={styles.headlineStyle}>$CUCK (Cat Duck)</h1>
                <h3 style={styles.subtitleStyle}>
                Hi I'm a cat duck so I'm a $CUCK. $CUCK is a CTO.
                50% Cat + 50% Duck = 100% CUCK.
                </h3>

                {
                    appContext.mobileMode?
                    <Link style={styles.downloadButtonStyle} onClick={() => {window.open("https://t.me/cuckctoSOL", '_blank').focus();}}>
                        Join Our Community
                    </Link>:<div style={styles.logoContainerStyle}>
                    <Link style={styles.downloadButtonStyle} onClick={() => {window.open("https://t.me/cuckctoSOL", '_blank').focus();}}>
                        Join Our Community
                    </Link>
                    <Link style={styles.whitepaperButtonStyle} onClick={() => {window.open("https://catduck.lol/whitepaper.pdf", '_blank').focus();}}>
                        Whitepaper
                    </Link>
                    </div>
                }


                
                <div style={styles.logoContainerStyle}>
                    <a href="https://dexscreener.com/solana/2pvntbmbea1oj9mzsh6wzxa8tgnhfvimczq48vmrgszk" target="_blank" rel="noopener noreferrer">
                        <Image style={styles.socialsIcon} src={Dexcreener} />
                    </a>
                    <a href="https://www.dextools.io/app/en/solana/pair-explorer/2pVnTbMBea1oJ9MZsh6WzxA8TgnHfVimCZQ48vmRGSzK" target="_blank" rel="noopener noreferrer">
                        <Image style={styles.socialsIcon} src={Dextools} />
                    </a>
                    <a href="https://www.coingecko.com/en/coins/cat-duck" target="_blank" rel="noopener noreferrer">
                        <Image style={styles.socialsIcon} src={Coingecko} />
                    </a>
                    <a href="https://x.com/Cucksol_CTO" target="_blank" rel="noopener noreferrer">
                        <Image style={styles.socialsIcon} src={Twitter} />
                    </a>
                    <a href="https://t.me/cuckctosolana" target="_blank" rel="noopener noreferrer">
                        <Image style={styles.socialsIcon} src={Telegram} />
                    </a>
                </div>

                {
                    appContext.mobileMode?
                    <Link style={styles.whitepaperButtonStyle} onClick={() => {window.open("https://catduck.lol/whitepaper.pdf", '_blank').focus();}}>
                        Whitepaper
                    </Link>:<></>
                }

                

            </div>
        </>
    )
}

export default MainSection;